import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    alertMessage:'',
    currUser:''
  },
  
  mutations: {
    LOG_USER: (state, currUser) => {
      state.currUser = currUser
    },

    ALERT_MESSAGE: (state, alertMessage) => {
      state.alertMessage = alertMessage
    }
  },
  actions: {
  },
  modules: {
  }
})
