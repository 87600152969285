<template>
  <div class="information_inputs" @mousemove="mouseUpdate">

    <form @submit.prevent="submitForm">

      <div class="tbl_row">
        <label for="brief_name">Название проекта</label>
        <input id="brief_name" type="text" v-model="briefName">
      </div>

      <div class="tbl_row">
        <label for="brief_website">Действующий сайт проекта (если есть)</label>
        <input id="brief_website" type="text" v-model="briefWebsite">
      </div>

      <div class="tbl_row brief_type unpadding10">
        <label>Укажите виды необходимых работ</label>
        <a @click="serviceType('brand', $event)">Создание бренда</a>
        <a @click="serviceType('identity', $event)">Айдентика</a>
        <a @click="serviceType('website', $event)">Разработка сайта</a>
        <a @click="serviceType('image', $event)">Имиджевые материалы</a>
        <a @click="serviceType('smm', $event)">Оформление соц.сетей</a>
        <a @click="serviceType('infographics', $event)">Инфографика</a>
        <a @click="serviceType('presentation', $event)">Презентация</a>
        <a @click="e => e.target.classList.toggle('active')">Дизайн печатных материалов</a>
      </div>

      <div class="tbl_row brand_exist">
        <label>Есть ли утвержденная используемая корпоративная стилистика и бренд?</label>
        <div>
          <a onselectstart="return false" @click="brandExist=true" :class="{active:brandExist}">Да</a>
          <a onselectstart="return false" @click="brandExist=false" :class="{active:!brandExist}">Нет</a>
        </div>
      </div>

      <div class="tbl_row">
        <label for="brief_rival">Укажите конкурентов в вашей сфере деятельности (если есть)</label>
        <input id="brief_rival" type="text" v-model="briefRival">
      </div>

      <div class="tbl_row brief_audience">
        <label for="brief_audience">Опишите целевую аудиторию для проекта</label>
        <input id="brief_audience" type="text" v-model="briefAudience">

        <div>
          <!-- <label for="rangeMin">Возраст</label>
          <input type="range" id="rangeMin" name="volume" min="0" max="25" v-model="briefAudienceMin">
          <p>от {{briefAudienceMin}}</p> -->

          <label>Возраст представителя целевой аудитории</label>
          <div onselectstart="return false" id="range_slider" ref="minData" @mousedown="rangeSlider" @mouseleave='rangeMouseLeave'>
            <div class="range_line" 
              :style="{ 'margin-left': valueMin + difference + 'px', 'width': 100 - briefAudienceMin - (100 - briefAudienceMax) + '%' }">
            </div>
            <a @mousedown='rangeMinPointClicked' @mouseup='mouseLeaveMin' :style="{ left: valueMin + difference + 'px' }">
              <p>от {{briefAudienceMin}}</p>
            </a>
            <a @mousedown='rangeMaxPointClicked' @mouseup='mouseLeaveMax' :style="{ left: valueMax + difference2 + 'px' }">
              <p>до {{briefAudienceMax}}</p>
            </a>
          </div>

          <div class="audience_age_mobile">
            от <input type="tel" v-model="briefAudienceMin">
            до <input type="tel" v-model="briefAudienceMax">
          </div>
        </div>

        <div class="audience_sex_choose">
          <label>Пол</label>
          <a @click="audienceSex('M', $event)">Муж</a>
          <a @click="audienceSex('W', $event)">Жен</a>
        </div>

      </div>

      <div class="tbl_row examples">
        <label>Удачные на ваш взгляд примеры подобных проектов</label>
        <input type="text" v-model="briefExampleGood1"><a v-if="briefExamplesGood===1" @click="briefExamplesGood=2"></a>
        <input type="text" v-if="briefExamplesGood>1" v-model="briefExampleGood2"><a v-if="briefExamplesGood===2" @click="briefExamplesGood=3"></a>
        <input type="text" v-if="briefExamplesGood==3" v-model="briefExampleGood3">
      </div>

      <div class="tbl_row examples">
        <label>Неудачные на ваш взгляд примеры подобных проектов</label>
        <input type="text" v-model="briefExampleBad1"><a v-if="briefExamplesBad===1" @click="briefExamplesBad=2"></a>
        <input type="text" v-if="briefExamplesBad>1" v-model="briefExampleBad2"><a v-if="briefExamplesBad===2" @click="briefExamplesBad=3"></a>
        <input type="text" v-if="briefExamplesBad==3" v-model="briefExampleBad3">
      </div>

      <div class="tbl_row">
        <label for="brief_info">Кратко опишите пожелания по основной задаче проекта</label>
        <textarea id="brief_info" cols="30" rows="10" v-model="briefInfo"></textarea>
      </div>

      <div class="tbl_row add_docs">
        <label for="docs">Прикрепить необходимые документы</label>
        <input id="docs" type="file" @change="onFilePicked">
        
        <span v-if="docsNames.length > 0">Загружено:</span>
        <p v-for="(docName, index) in docsNames" v-bind:key="index">
          {{docName}}
        </p>
      </div>


      <div class="tbl_row contact_info">
        <label>Укажите данные для связи</label>
        <input type="text" v-model="briefAuthor" placeholder="ваше имя">
        <input type="text" v-model="briefContact" placeholder="телефон или почта">
      </div>

      <div class="tbl_row">
        <input type="submit" value="Отправить">
      </div>

    </form>

  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import firebase from 'firebase'
import 'firebase/storage'

export default {
  name: 'Brief',
  data() {
    return {
      briefName:'',
      briefWebsite:'',
      briefInfo:'',
      briefService:[],

      brandExist:'',
      
      briefRival:'',
      briefAudience:'',
      briefAudienceMin:14,
      briefAudienceMax:80,
      briefAudienceSex:'',

      briefExamplesGood:1,
      briefExampleGood1:'',
      briefExampleGood2:'',
      briefExampleGood3:'',

      briefExamplesBad:1,
      briefExampleBad1:'',
      briefExampleBad2:'',
      briefExampleBad3:'',
      
      testMouseDown:false,
      clickedTestX:0,
      clickedTestX2:0,
      clickedRange:'',
      difference:0,
      difference2:0,
      valueMin:56,
      valueMax:318,

      uploadValue:0,
      docsUrls:[],
      docsNames:[],

      briefAuthor:'',
      briefContact:''
    }
  },

  computed: {
    ...mapState([
    'alertMessage', 'alertType'
    ])
  },

  methods:{
    ...mapMutations([ 'ALERT_MESSAGE' ]),

    serviceType(service, event) {
      event.target.classList.toggle('active');

      var existNum = -1;
      for (let i = 0; i < this.briefService.length+1; i++) {
        if (this.briefService[i]===service) { existNum = i; }; 
      };

      if (existNum>=0) { this.briefService.splice(existNum, 1); } 
        else { this.briefService.push(service); }
    },


    audienceSex(sex, event) {
      event.target.classList.toggle('active');

      if (this.briefAudienceSex===''){ this.briefAudienceSex = sex } 
        else if (sex!==this.briefAudienceSex && this.briefAudienceSex!=='all'){ this.briefAudienceSex = 'all' } 
        else if (sex===this.briefAudienceSex){ this.briefAudienceSex = '' } 
        else if (this.briefAudienceSex==='all'){ 
          if (sex==='M') {this.briefAudienceSex = 'W'};
          if (sex==='W') {this.briefAudienceSex = 'M'};
      }
    },


    // --------
    // range changing
    rangeSlider(){ this.testMouseDown = true; },  
    rangeMinPointClicked(event){ this.clickedTestX = event.pageX; this.clickedRange = 'min' },
    rangeMaxPointClicked(event){ this.clickedTestX2 = event.pageX; this.clickedRange = 'max' },
    mouseUpdate(event){ // mouse moved
      if (this.clickedRange === 'min') { this.rangeMinChange(event); }
      if (this.clickedRange === 'max') { this.rangeMaxChange(event); }
    },
    rangeMinChange(event){ 
      if (this.testMouseDown) {
        this.difference = event.pageX - this.clickedTestX;
        // min value of range
        if (this.valueMin + this.difference < 56) { this.difference = 0; this.valueMin = 56; };
        
        // get max range position and stop increase
        if (this.valueMin + this.difference > this.valueMax-60) {
          this.difference = 0; this.valueMin = this.valueMax-60;
        };

        this.briefAudienceMin = Math.round(((this.valueMin + this.difference) / 400) * 100);
      };
    },
    rangeMaxChange(event){ // mouse moved
      if (this.testMouseDown) {
        this.difference2 = event.pageX - this.clickedTestX2;
        // max value of range
        if (this.valueMax + this.difference2 > 356) { this.difference2 = 0; this.valueMax = 356; };
        
        // get min range position and stop decrease
        if (this.valueMax + this.difference2 < this.valueMin+60) {
          this.difference2 = 0; this.valueMax = this.valueMin+60;
        };

        this.briefAudienceMax = Math.round(((this.valueMax + this.difference2) / 400) * 100);
      };
    },
    mouseLeaveMin(){
      this.testMouseDown=false;
      if (this.valueMin===0) { this.valueMin = this.difference; } // first range change
      else { this.valueMin = this.valueMin + this.difference; };
      this.difference = 0;
    },
    mouseLeaveMax(){
      this.testMouseDown=false;
      if (this.valueMax===0) { this.valueMax = this.difference2; } // first range change
      else { this.valueMax = this.valueMax + this.difference2; };
      this.difference2 = 0;
    },
    rangeMouseLeave(){ 
      this.testMouseDown=false; this.difference = 0; this.difference2 = 0; 
      this.briefAudienceMin = Math.round(((this.valueMin + this.difference) / 400) * 100);
      this.briefAudienceMax = Math.round(((this.valueMax + this.difference2) / 400) * 100);
    },
    // --------


    // add docs
    onFilePicked(event){
      let file = event.target.files[0];
      if (!file.name) { return };

      console.log('FILE NAME IS: ' + file.name);
      
      // UPLOAD file
      var uploadTask = firebase.storage().ref('briefs/'+this.briefName+'/'+file.name).put(file);

      uploadTask.on('state_changed', 
        (snapshot) => {
          this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + this.uploadValue + '% done');
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running');
              break;
          }
        }, 
        (error) => { console.log(error); }, 
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
            this.docsUrls.push(downloadURL);
            this.docsNames.push(file.name);
          });
        }
      );

    },


    submitForm(){
      console.log('Brief: ' + this.briefName);

      var totalExamplesGood = '';
      if (this.briefExamplesGood===2) { totalExamplesGood = this.briefExampleGood1 + ' // ' + this.briefExampleGood2 }
      if (this.briefExamplesGood===3) { totalExamplesGood = this.briefExampleGood1 + ' // ' + this.briefExampleGood2 + ' // ' + this.briefExampleGood3 }
      var totalExamplesBad = '';
      if (this.briefExamplesBad===2) { totalExamplesBad = this.briefExampleBad1 + ' // ' + this.briefExampleBad2 }
      if (this.briefExamplesBad===3) { totalExamplesBad = this.briefExampleBad1 + ' // ' + this.briefExampleBad2 + ' // ' + this.briefExampleBad3 }

      db.collection("briefs").add({
          name: this.briefName,
          website: this.briefWebsite,
          services: this.briefService,
          branding: this.brandExist,
          rivals: this.briefRival,
          audience: this.briefAudience,
          aud_age: this.briefAudienceMin.toString() + '-' + this.briefAudienceMax.toString(),
          aud_sex: this.briefAudienceSex,
          example_good: totalExamplesGood,
          example_bad: totalExamplesBad,
          info: this.briefInfo,
          docs: this.docsUrls,
          author: this.briefAuthor,
          author_contact: this.briefContact
      })
      .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
      })
      .catch((error) => {
          console.error("Error adding document: ", error);
      });

      this.ALERT_MESSAGE({ 
        alertText: 'Бриф успешно отправлен', 
        alertType: 'info',
      });

      setTimeout(() => this.$router.go(), 2900);
      window.scrollTo(0,0);
    }
  }

}

</script>
