<template>
  <div id="home">

    <h1>Home page of the brief application</h1>

    <p>Укажите желаемые параметры проекта</p>

  </div>
</template>

<script>

export default {
  name: 'Home',

  data() {
    return {
      menuOpened:false

    } 
  },

  methods:{



  }
}
</script>
