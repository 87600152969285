<template>
  <div id="app">

    <!-- <router-view class="content" /> -->

    <div class="logo">Grafactor development<br>brief automation <br>system</div>

    <div class="content">

      <h1>Бриф на разработку</h1>
      <p>Укажите желаемые параметры проекта. Детали описания задачи позволят правильнее и оперативнее осуществить все необходимые элементы разработки.</p>

      <MainForm />

    </div>

    <div class="app-ver">Connected with <b>GraTask</b> ver.0.01</div>

    <Alert v-if="alertMessage"/>

  </div>
</template>


<script>
import Alert from '@/components/Alert.vue'
import MainForm from '@/components/MainForm.vue'

import { mapState, mapMutations } from 'vuex'

export default {
  name: 'App',
  metaInfo: {
      title: 'Grafactor brief'
    },

  components: {
    Alert, MainForm
  },

  data() {
    return {
      alreadyLogged:''
    } 
  },

  created() {
    document.title = "Brief by Grafactor";

  },

  computed: {
    ...mapState([
    'alertMessage'
    ]),

  },

  methods:{
    ...mapMutations([ 'LOG_USER' ]),
    
  }

}
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400&display=swap');
@import 'assets/css/style.css';
@import 'assets/css/style-mobile.css';

</style>
