<template>
  <div class="alert" :class="{
    positive:alertMessage.alertType==='info', 
    attention:alertMessage.alertType==='attention',
    negative:alertMessage.alertType==='alarm'
    }">

    <p>{{alertMessage.alertText}}</p>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Alert',

  created(){
    setTimeout(() => this.ALERT_MESSAGE(''), 3000);
  },

  computed: {
    ...mapState([
    'alertMessage', 'alertType'
    ])
  },

  methods:{
    ...mapMutations([ 'ALERT_MESSAGE' ])
  }

}

</script>
