import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import firebase from 'firebase'
import 'firebase/storage'

require('firebase/firestore')
var firebaseConfig = {
  apiKey: "AIzaSyBdH5Pe_Pdwk9DDAy2_r0m0n4MHAtvyfiI",
  authDomain: "gratask-c110c.firebaseapp.com",
  projectId: "gratask-c110c",
  storageBucket: "gratask-c110c.appspot.com",
  messagingSenderId: "810096790548",
  appId: "1:810096790548:web:91510e75ffb010738dab01",
  measurementId: "G-S7VMY8TDE1"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

var db = firebase.firestore()

window.db = db

// disable deprecated features
db.settings({
  timestampsInSnapshots: true
})



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
